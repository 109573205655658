import {Container, Divider, Grid, Skeleton} from "@mui/material";
import { createSvgIcon } from '@mui/material/utils';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

const CloseIcon = createSvgIcon(
    // credit: plus icon from https://heroicons.com/
    <svg viewPort="0 0 12 12" version="1.1"
         xmlns="http://www.w3.org/2000/svg"
            width={40}
         height={40}>
        <line x1="1" y1="11"
              x2="11" y2="1"
              stroke="black"
              stroke-width="2"/>
        <line x1="1" y1="1"
              x2="11" y2="11"
              stroke="black"
              stroke-width="2"/>
    </svg>,
    'Plus',
);

const Drawer = ({project, setOpen}) => {
    return (
        <Container maxWidth="md" className="center app-drawer">
            <a className="close-button"  onClick={() => setOpen(false)}>
                <CloseIcon />
            </a>
            <h1>{project.title}</h1>
            {(project.drawer?? []).map((section, i) => {
                return (
                    <>
                        {!(i===0) &&
                            <Divider className="margin-y-xl" sx={{ flexGrow: 1}} />
                        }
                        <h3>{section.title?? ''}</h3>
                        <p>{section.desc?? ''}</p>
                        {(section.images?.length ?? []) > 0 &&
                            <Grid container spacing={1} className="margin-t-xs">
                                {Object.values(section.images).map((image, i) => {
                                    return (
                                        <Grid item xs={image.size}>
                                            <LazyLoadComponent>
                                                <img
                                                    src={image.src}
                                                    alt={image.alt}
                                                    loading="lazy"
                                                />
                                            </LazyLoadComponent>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        }

                        {(section.videos?.length ?? []) > 0 &&
                            <Grid container spacing={2} className="margin-t-xs">
                                {Object.values(section?.videos).map((video, i) => {
                                    return (
                                        <>
                                            <Grid item xs={12}>
                                                <div class="module-video">
                                                    <LazyLoadComponent>
                                                        <video autoPlay loop muted playsInline controls>
                                                            <source src={'videos/'+video.src} type="video/mp4" />
                                                        </video>
                                                    </LazyLoadComponent>
                                                </div>
                                            </Grid>

                                            {video.image &&
                                                <Grid item xs={video.image}>
                                                    <LazyLoadComponent>
                                                        <img
                                                            src={video.image}
                                                            loading="lazy"
                                                        />
                                                    </LazyLoadComponent>
                                                </Grid>
                                            }
                                        </>
                                    )
                                })}
                            </Grid>
                        }
                    </>
                )
            })}
        </Container>
    )
};

export default Drawer;
