import video from '../videos/little_guy.mp4';
import footerVideo from '../videos/little-guy-lounging.mp4';
import {Link} from "react-router-dom";
import { useTrail, animated, a } from '@react-spring/web'
import TrackVisibility from 'react-on-screen';
import {Divider, Grid} from "@mui/material";
import {useRef, useState} from "react";
import Work from "./Work";

const Home = () => {
    const videoRef = useRef();
    const [animated, setAnimated] = useState(true);
    const trail = useTrail(1, {
        config: { mass: 50, tension: 3000, friction: 1000 },
        delay: 300,
        opacity: animated ? 1 : 0,
        y: animated ? 0 : 40,
        height: animated ? 95 : 0,
        from: { opacity: 0, y: 40, height: 0 },
    });
    // run this function from an event handler or an effect to execute scroll

  return (
      <>
        <section className="logo margin-y-0">
            <Divider textAlign="center">
                <div className="video-box">
                    <video width={200} height={200} ref={videoRef} autoPlay loop muted playsInline>
                        <source src={video} type="video/mp4" />
                    </video>
                </div>
            </Divider>
        </section>
        <section id="work" className="work margin-t-0">
            {/*<h2>Work</h2>*/}
            {/*<Grid container>*/}
            {/*    <Grid item xs={6} className="tile">*/}
            {/*        <img*/}
            {/*            src="https://placehold.co/1200x800"*/}
            {/*            alt="placeholder"*/}
            {/*            loading="lazy"*/}
            {/*        />*/}
            {/*        <h4>Boost Mobile</h4>*/}
            {/*    </Grid>*/}
            {/*    <Divider orientation="vertical" variant="middle" flexItem sx={{ mr: "-1px" }} />*/}
            {/*    <Grid item xs={6} className="tile">*/}
            {/*        <img*/}
            {/*            src="https://placehold.co/1200x800"*/}
            {/*            alt="placeholder"*/}
            {/*            loading="lazy"*/}
            {/*        />*/}
            {/*        <h4>Motion Graphics</h4>*/}
            {/*    </Grid>*/}
            {/*    <Divider orientation="vertical" variant="middle" flexItem sx={{ mr: "-1px", display: "none"}} />*/}
            {/*</Grid>*/}
            {/*<Link to="/work" className="app-link">More Work</Link>*/}
            <Work />
        </section>
        <Divider />
        <section id="about" className="about">
            <Grid container>
                <Grid item sm={12} md={6} className="tile">
                    <TrackVisibility>
                        {({ isVisible }) => {
                            setAnimated(isVisible);
                            return (
                                <div className="trails-wrapper">
                                    {trail.map(({ height, ...style }, index) => (
                                        <a.div className="trailsText" style={style}>
                                            <a.div style={height}>About</a.div>
                                        </a.div>
                                    ))}
                                </div>
                            )
                        }}
                    </TrackVisibility>
                    {/*<h2>About</h2>*/}
                    <p className="text-24 margin-b-s">Hello, I’m Cody Gall, a brand and motion designer
                    looking for new opportunities in the San Francisco
                    Bay Area. I specialize in brand design and motion
                    graphic production. Have a look at my resume to
                        see my full credentials.</p>
                </Grid>
                <Divider orientation="vertical" variant="middle" className="hide-md" flexItem sx={{ mr: "-1px" }} />
                <Grid item sm={12} md={6} className="tile align-end">
                    <ul className="double margin-b-s">
                        <li>Adobe CC</li>
                        <li>Figma</li>
                        <li>Video Production</li>
                        <li>Motion Graphics</li>
                        <li>Web Design</li>
                        <li>UI/UX Design</li>
                        <li>Branding</li>
                        <li>Marketing</li>
                        <li>Illustration</li>
                        <li>Layouts</li>
                    </ul>
                </Grid>
                <Divider orientation="vertical" variant="middle" className="hide-md" flexItem sx={{ mr: "-1px", display: "none"}} />
            </Grid>
            {/*<div className="flex center width-20">*/}
            {/*    <a href="" className="app-link">Resume</a>*/}
            {/*    <a href="" className="app-link">LinkedIn</a>*/}
            {/*</div>*/}
        </section>
          <section className="logo margin-y-0">
              <Divider textAlign="center">
                  <div className="video-box">
                      <video width={200} height={200} ref={videoRef} autoPlay loop muted playsInline>
                          <source src={footerVideo} type="video/mp4" />
                      </video>
                  </div>
              </Divider>
          </section>
        {/*<Divider />*/}
        {/*<section className="video"></section>*/}
      </>
  );
}

export default Home;
