import { Outlet, Link } from "react-router-dom";
import {a} from "@react-spring/web";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import {useState} from "react";

const Layout = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const executeScroll=(id)=>{
        const el = document.getElementById(id);
        el.scrollIntoView({
            behavior: 'smooth',
        });
        return true;
    };
    return (
        <>
            <header className="app-header">
                <span className="app-heading">
                    <a onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>Cody Gall</a>
                </span>
                <nav className="app-nav hide-sm">
                    <a onClick={() => executeScroll('work')}>Work</a>
                    <a onClick={() => executeScroll('about')}>About</a>
                    <a target="_blank" href="docs/CodyGallResume2023.pdf">Resume</a>
                    <a target="_blank" href="https://www.linkedin.com/in/cody-gall-a0727931/">LinkedIn</a>
                </nav>
                <div className="hide-sm-up margin-l-a">
                    <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                        className="padding-t-0"
                    >
                        <MenuIcon className="app-menu-button padding-t-0" />
                    </IconButton>
                    <Menu
                        className="app-menu"
                        id="long-menu"
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                width: '20ch',
                            },
                        }}
                    >
                        <MenuItem>
                            <a onClick={() => executeScroll('work')}>Work</a>
                        </MenuItem>
                        <MenuItem>
                            <a onClick={() => executeScroll('about')}>About</a>
                        </MenuItem>
                        <MenuItem>
                            <a target="_blank" href="docs/CodyGallResume2023.pdf">Resume</a>
                        </MenuItem>
                        <MenuItem>
                            <a target="_blank" href="https://www.linkedin.com/in/cody-gall-a0727931/">LinkedIn</a>
                        </MenuItem>
                    </Menu>
                </div>
            </header>
            <div className="app">
                <Outlet />
            </div>
        </>
    )
};

export default Layout;
