import * as CONTENT from './content/drawer.js';
import {VIDE_CONTENT, VIDEO_CONTENT} from "./content/drawer.js";
export const WORK_GRID = {
    1: {
        id: 1,
        title: 'Boost Mobile',
        subtitle: 'Boost Mobile Microsites & Landing Pages',
        image: 'images/home-bm.png',
        drawer: CONTENT.BOOST_CONTENT,
    },
    2: {
        id: 2,
        title: 'Videos',
        subtitle: 'Boost Mobile Microsites & Landing Pages',
        image: 'images/home-video.png',
        drawer: CONTENT.VIDEO_CONTENT,
    },
    3: {
        id: 3,
        title: 'UI Design',
        subtitle: 'Boost Mobile Microsites & Landing Pages',
        image: 'images/home-nintendo.png',
        body: 'Boost Mobile SEO blurb Lorem ipsum dolor sit amet, consectetuer adipiscing ' +
            'elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna ' +
            'aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci ' +
            'tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat',
        drawer: CONTENT.NINTENDO_CONTENT,
    },
    4: {
        id: 4,
        title: 'Web Design',
        subtitle: 'Boost Mobile Microsites & Landing Pages',
        image: 'images/home-web.png',
        body: 'Boost Mobile SEO blurb Lorem ipsum dolor sit amet, consectetuer adipiscing ' +
            'elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna ' +
            'aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci ' +
            'tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat',
        drawer: CONTENT.WEB_DESIGN,
    },
    5: {
        id: 5,
        title: 'Whole Foods',
        subtitle: 'Boost Mobile Microsites & Landing Pages',
        image: 'images/home-wf.png',
        body: 'Boost Mobile SEO blurb Lorem ipsum dolor sit amet, consectetuer adipiscing ' +
            'elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna ' +
            'aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci ' +
            'tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat',
        drawer: CONTENT.WHOLE_FOODS,
    },
    6: {
        id: 6,
        title: 'Illustration / Design',
        subtitle: 'Boost Mobile Microsites & Landing Pages',
        image: 'images/home-illustration.png',
        body: 'Boost Mobile SEO blurb Lorem ipsum dolor sit amet, consectetuer adipiscing ' +
            'elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna ' +
            'aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci ' +
            'tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat',
        drawer: CONTENT.DESIGN_CONTENT,
    },
}
// export const GAMES = {
//     1: {
//         id: 1,
//         number: '001',
//         name: 'The Legend of Zelda: Oracle of Seasons',
//         sideA: '001_Oracle_of_Seasons.png',
//         sideB: '001_Oracle_of_Seasons_B.png',
//         platform: PLATFORMS.gbc,
//     },
//     2: {
//         id: 2,
//         number: '002',
//         name: 'The Legend of Zelda: Oracle of Ages',
//         sideA: '003_Oracle_of_Ages.png',
//         sideB: '003_Oracle_of_Ages_B.png',
//         platform: PLATFORMS.gbc,
//     },
//     3: {
//         id: 3,
//         number: '003',
//         name: 'The Legend of Zelda: Minish Cap',
//         sideA: '004_Minish_Cap.png',
//         sideB: '004_Minish_Cap_B.png',
//         platform: PLATFORMS.gbc,
//     },
//     4: {
//         id: 4,
//         number: '004',
//         name: 'Chrono Cross',
//         sideA: '002_Chrono_Cross.png',
//         sideB: '002_Chrono_Cross_B.png',
//         platform: PLATFORMS.ps,
//     },
//     5: {
//         id: 5,
//         number: '005',
//         name: 'Wario Ware, Inc.',
//         sideA: '005_WarioWare.png',
//         sideB: '005_WarioWare_B.png',
//         platform: PLATFORMS.gbc,
//     },
//     6: {
//         id: 6,
//         number: '006',
//         name: 'The Legend of Zelda: Phantom Hourglass',
//         sideA: '006_Phantom_Hourglass.png',
//         sideB: '006_Phantom_Hourglass_B.png',
//         platform: PLATFORMS.ds,
//     },
//     7: {
//         id: 7,
//         number: '007',
//         name: 'Final Fantasy Theatrhythm Final Bar Line',
//         sideA: '007_Theatrhythm.png',
//         sideB: '007_Theatrhythm_B.png',
//         platform: PLATFORMS.switch,
//     },
//     8: {
//         id: 8,
//         number: '008',
//         name: 'Kirby\'s Return to Dreamland',
//         sideA: '008_Kirby_Return_to_Dream_Land.png',
//         sideB: '008_Kirby_Return_to_Dream_Land_B.png',
//         platform: PLATFORMS.switch,
//     },
//     9: {
//         id: 9,
//         number: '008',
//         name: 'Kirby\'s Return to Dreamland: Magalor Epilogue',
//         sideA: '008_Kirby_Return_to_Dream_Land-1.png',
//         sideB: '008_Kirby_Return_to_Dream_Land_B-1.png',
//         platform: PLATFORMS.switch,
//     },
//     10: {
//         id: 10,
//         number: '009',
//         name: 'The Legend of Zelda: Ocarina of Time',
//         sideA: '009_Ocarina_of_Time.png',
//         sideB: '009_Ocarina_of_Time_B.png',
//         platform: PLATFORMS.n64,
//     },
//     11: {
//         id: 11,
//         number: '010',
//         name: 'Metroid Prime Remastered',
//         sideA: '010_Metroid_Prime.png',
//         sideB: '010_Metroid_Prime_B.png',
//         platform: PLATFORMS.switch,
//     },
// }
