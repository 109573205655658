export const BOOST_CONTENT = [
    {
        title: 'Sub-Brand Designs',
        desc: 'While working at Boost Mobile, I designed two distinctive sub-brands, Project Genesis and Boost Infinite. Project Genesis stood on it\'s own and was characterized by a futuristic and cutting-edge aesthetic, while Boost Infinite exuded a timeless and universal appeal while staying close to Boost Mobile\'s branding.',
        images: [
            {
                src: 'images/bmdesign/genesiswide.png',
                alt: '',
                size: 12
            },
            {
                src: 'images/bmdesign/belight.png',
                alt: '',
                size: 12
            },
            {
                src: 'images/bmdesign/qr.png',
                alt: '',
                size: 6
            },
            {
                src: 'images/bmdesign/infinite.png',
                alt: '',
                size: 6
            }
        ],
        
    },

    {
        title: 'Boost Mobile Logos',
        desc: 'These are logos I made for Boost Mobile. The streetwear logo was made as a fun one-off clothing line for influencers we were working with, and the other was for the new customer-facing app, BoostOne.',
        images: [
            {
                src: 'images/bmdesign/streetlogo.png',
                alt: '',
                size: 12
            },
            {
                src: 'images/bmdesign/streetlogoblack.png',
                alt: '',
                size: 6
            },
            {
                src: 'images/bmdesign/streetclothes.png',
                alt: '',
                size: 6
            },
            {
                src: 'images/bmdesign/boostone.png',
                alt: '',
                size: 12
            },
        ],
    },

    {
        title: 'Boost Mobile Social Media',
        desc: 'Some assorted social media projects and imagery I\'ve created over my time with Boost Mobile',
        images: [
            {
                src: 'images/bmdesign/social-triple.png',
                alt: '',
                size: 12
            },
            {
                src: 'images/bmdesign/social-mlk.png',
                alt: '',
                size: 6
            },
            {
                src: 'images/bmdesign/social-map.png',
                alt: '',
                size: 6
            },
            {
                src: 'images/bmdesign/social-phones.png',
                alt: '',
                size: 6
            },
            {
                src: 'images/bmdesign/social-flex.png',
                alt: '',
                size: 6
            },
        ],
    },

    {
        title: 'Project Genesis Influencer Box',
        desc: 'This is a box designed to give to influencers for the launch of Project Genesis, a sub-brand of Boost Mobile.',
        images: [
            {
                src: 'images/bmdesign/pgbox1.png',
                alt: '',
                size: 12
            },
            {
                src: 'images/bmdesign/pgbox2.png',
                alt: '',
                size: 6
            },
            {
                src: 'images/bmdesign/pgbox3.png',
                alt: '',
                size: 6
            },
        ],
    },
]

export const VIDEO_CONTENT = [
    {
        title: 'Boost Mobile Video Production',
        videos: [
            {
                src: 'videopage/assortedBM-videos.mp4',
                image: '',
            },
        ]
    },
    {
        videos: [
            {
                src: 'videopage/animatedsocials-footage.mp4',
                image: '',
            },
        ]
    },
    {
        videos: [
            {
                src: 'videopage/channelb-2023.mp4',
                image: '',
            },
        ]
    },
    {
        videos: [
            {
                src: 'videopage/phonestartup-animations.mp4',
                image: '',
            },
        ]
    },
    {
        videos: [
            {
                src: 'videopage/gametitle.mp4',
                image: '',
            },
        ]
    },
    {
        videos: [
            {
                src: 'videopage/strategy-work.mp4',
                image: '',
            },
        ]
    },
    {
        videos: [
            {
                src: 'videopage/strategy-client.mp4',
                image: '',
            },
        ]
    },
]

export const WEB_DESIGN = [
    {
        title: 'Best Buy Boost Mobile Partner Page',
        desc: 'A partner page designed to live on the Best Buy website.',
        videos: [
            {
                src: 'web/1-bestbuy-scroll.mp4',
                image: 'images/webdesign/bestbuy.png',
            },
        ],
    },
    {
        title: 'Celero Launch Microsite',
        desc: 'Microsite made to support launch of the new phone Celero',
        videos: [
            {
                src: 'web/2-celerolaunch-scroll.mp4',
                image: 'images/webdesign/celero.png',
            },
        ],
    },
    {
        title: 'New Plan Promo Landing Page',
        desc: 'Landing page designed to ',
        videos: [
            {
                src: 'web/3-freesim-scroll.mp4',
                image: 'images/webdesign/plans.png',
            },
        ],
    },
    {
        title: 'Step Up to the Mic Microsite',
        desc: '',
        videos: [
            {
                src: 'web/5-suttm-scroll.mp4',
                image: 'images/webdesign/suttm.png',
            },
        ],
    },
    {
        title: 'Mathis Exterminating Web Design',
        desc: 'A custom website created for Mathis',
        videos: [
            {
                src: 'web/6-mathis-scroll.mp4',
                image: 'images/webdesign/mathis.png',
            },
        ],
    },
    {
        title: 'Northeast Community College Web Design',
        desc: 'A custom website featuring a student portal for NECC',
        videos: [
            {
                src: 'web/7-necc-scroll.mp4',
                image: 'images/webdesign/necc.png',
            },
        ],
    },


]

export const WHOLE_FOODS = [
    {
        title: 'Chalkboard Illustrations',
        images: [
            {
                src: 'images/chalkboards/peach.png',
                alt: '',
                size: 12
            },
            {
                src: 'images/chalkboards/cheese-board.png',
                alt: '',
                size: 6
            },
            {
                src: 'images/chalkboards/baguette.png',
                alt: '',
                size: 6
            },
            {
                src: 'images/chalkboards/cookies.png',
                alt: '',
                size: 6
            },
            {
                src: 'images/chalkboards/hot-cross-buns.png',
                alt: '',
                size: 6
            },
            {
                src: 'images/chalkboards/orange-cake.png',
                alt: '',
                size: 6
            },
            {
                src: 'images/chalkboards/fruit-tart.png',
                alt: '',
                size: 6
            },
            {
                src: 'images/chalkboards/swordfish.png',
                alt: '',
                size: 6
            },
            {
                src: 'images/chalkboards/farmed-shrimp.png',
                alt: '',
                size: 6
            },
            {
                src: 'images/chalkboards/rib-roast.png',
                alt: '',
                size: 6
            },
            {
                src: 'images/chalkboards/flowers.png',
                alt: '',
                size: 6
            },
            {
                src: 'images/chalkboards/no-added-hormones.png',
                alt: '',
                size: 12
            },
        ]
    },
    {
        title: 'Whole Foods Print Design',
        desc: '',
        images: [
            {
                src: 'images/wfdesign/bakehouse.png',
                alt: '',
                size: 12
            },
            {
                src: 'images/wfdesign/catering.png',
                alt: '',
                size: 12
            },
            {
                src: 'images/wfdesign/menu.png',
                alt: '',
                size: 7
            },
            {
                src: 'images/wfdesign/welcome.png',
                alt: '',
                size: 5
            },
             {
                src: 'images/wfdesign/dividerfridge.png',
                alt: '',
                size: 5
            }, 
            {
                src: 'images/wfdesign/divider.png',
                alt: '',
                size: 7
            },
            {
                src: 'images/wfdesign/local.png',
                alt: '',
                size: 7
            },
            {
                src: 'images/wfdesign/localfridge.png',
                alt: '',
                size: 5
            },



        ]
    },
]

export const NINTENDO_CONTENT = [
    {
        title: 'Event Mobile App Design',
        desc: 'A mockup for an event based app focusing on UI design and animation.',
        videos: [
            {
                src: 'uivideos/eventapp.mp4',
                image: '',
            },
            {
                src: 'uivideos/scroll.mp4',
                image: '',
            },
        ],
        images: [
            {
                src: 'images/nintendo/eventapp-display-01.png',
                alt: '',
                size: 12
            },
             {
                src: 'images/nintendo/eventapp-display-02.png',
                alt: '',
                size: 12
            },
             {
                src: 'images/nintendo/eventapp-display-03.png',
                alt: '',
                size: 12
            },
             {
                src: 'images/nintendo/eventapp-display-04.png',
                alt: '',
                size: 12
            },
        ]
    },
    {
        title: 'Smash Bros. UI Redesign',
        desc: 'A UI redesign of Super Smash Bros. Ultimate for the Nintendo Switch. I wanted to convey simplicity in the options as that\'s something that the current UI has problems with.',
        images: [
            {
                src: 'images/nintendo/smash1.png',
                alt: '',
                size: 12
            },
             {
                src: 'images/nintendo/smash2menu.png',
                alt: '',
                size: 12
            },
             {
                src: 'images/nintendo/smash3character.png',
                alt: '',
                size: 12
            },
             {
                src: 'images/nintendo/smash4event.png',
                alt: '',
                size: 12
            },
        ]
    },
    {
        title: 'Poekmon Go UI Redesign',
        desc: 'A UI update to make the popular mobile game more navigable, and solve some of it\'s ease-of-use problems',
        images: [
            {
                src: 'images/nintendo/pokemain.png',
                alt: '',
                size: 5
            },
             {
                src: 'images/nintendo/poke2map.png',
                alt: '',
                size: 7
            },
             {
                src: 'images/nintendo/poke3collect.png',
                alt: '',
                size: 12
            },
             {
                src: 'images/nintendo/poke4trade.png',
                alt: '',
                size: 6
            },
             {
                src: 'images/nintendo/poke5items.png',
                alt: '',
                size: 6
            },
        ]
    },
]

export const DESIGN_CONTENT = [
    {
        title: 'Posters',
        images: [
            {
                src: 'images/illustration/samusstars.png',
                alt: '',
                size: 12
            },
            {
                src: 'images/illustration/twinpeaks.png',
                alt: '',
                size: 12
            },
        ]
    },
    {
        title: 'Tarot Deck',
        images: [
            {
                src: 'images/illustration/tarotdisplay-01.png',
                alt: '',
                size: 12
            },
            {
                src: 'images/illustration/tarotdisplay-02.png',
                alt: '',
                size: 12
            },
        ]
    },
]
